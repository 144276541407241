export function saveSearch(term: string) {
  if (!term) return;
  const text = localStorage.getItem('ecf_search_word') || '[]';
  let existingTerms: string[] = JSON.parse(text);

  // 중복된 검색어 제거 (중복 검색어가 있으면 기존 위치에서 제거)
  existingTerms = existingTerms.filter((ele) => ele !== term);

  // 새로운 검색어를 맨 앞으로 추가
  existingTerms.unshift(term);

  // 검색어가 50개를 초과하면 가장 오래된 검색어 삭제
  if(existingTerms.length > 50){
    existingTerms.pop();
  }

  // 업데이트된 검색어를 로컬스토리지에 저장
  localStorage.setItem('ecf_search_word', JSON.stringify(existingTerms));
}

export function deleteSearch(term: string,setWords: React.Dispatch<React.SetStateAction<string[]>>) {
  const text = localStorage.getItem('ecf_search_word') || '[]';
  let existingTerms: string[] = JSON.parse(text);

  // 해당 검색어를 목록에서 제거
  // existingTerms = existingTerms.filter((ele) => ele !== term);

  const filtered = existingTerms.filter((ele)=>ele !== term);



  // 로컬 스토리지에 업데이트된 검색어 목록에 저장
  localStorage.setItem('ecf_search_word', JSON.stringify(filtered));

  // 삭제된 후 화면에 표시
  setWords(filtered.slice(0, 9));

  return filtered;
}
