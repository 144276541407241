'use client';

import styled from '@emotion/styled';
import close from '@/public/close.svg';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { deleteSearch, saveSearch } from '@/util/searchFunc';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '@/api/axios';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '@/api/cookie';
import { theme } from '@/app/styles/theme';

interface HotKeywords {
    code: number;
    message: string;
    data: {
        keywords: string[];
    };
}
interface RecommendKeywords {
    code: number;
    message: string;
    data: {
        keywords: string[];
    };
}
interface RecentKeywords {
    code: number;
    message: string;
    data: {
        keywords: {
            recentKeyword: string;
            idx: string;
        }[];
    };
}

interface Props {
    closeFunc?: () => void;
}

// 1. Up, Down, Maintain styled-components 정의
const _rankUpIcon = styled.div`
    display: block;
    width: 10px;
    padding: 4px;
    :after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 0px;
        height: 0px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid rgb(255, 0, 0);
    }
`;

const _rankDownIcon = styled.div`
    display: block;
    width: 10px;
    padding: 4px;
    :after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 0px;
        height: 0px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgb(0, 154, 255);
    }
`;

const _rankMaintainIcon = styled.div`
    display: block;
    width: 10px;
    padding: 4px;
    :after {
        content: '';
        position: absolute;
        top: 50%;
        right: 2.5px;
        transform: translate(0, -50%);
        width: 5px;
        height: 2px;
        background-color: rgb(209, 209, 209);
    }
`;

// 2. 상태 정의
const STATUS = {
    UP: 'up',
    DOWN: 'down',
    MAINTAIN: 'maintain',
} as const;

// 3. RANK_STATUS 정의
const RANK_STATUS = {
    0: {
        icon: <_rankUpIcon />,
        status: STATUS.UP,
        color: '#e91237',
        label: '순위 상승',
    },
    1: {
        icon: <_rankDownIcon />,
        status: STATUS.DOWN,
        color: '#4d4dff',
        label: '순위 하락',
    },
    2: {
        icon: <_rankMaintainIcon />,
        status: STATUS.MAINTAIN,
        color: '#d1d1d1',
        label: '순위 유지',
    },
    3: {
        icon: <_rankUpIcon />,
        status: STATUS.UP,
        color: '#e91237',
        label: '순위 상승',
    },
    4: {
        icon: <_rankDownIcon />,
        status: STATUS.DOWN,
        color: '#4d4dff',
        label: '순위 하락',
    },
    5: {
        icon: <_rankMaintainIcon />,
        status: STATUS.MAINTAIN,
        color: '#d1d1d1',
        label: '순위 유지',
    },
    6: {
        icon: <_rankUpIcon />,
        status: STATUS.UP,
        color: '#e91237',
        label: '순위 상승',
    },
    7: {
        icon: <_rankDownIcon />,
        status: STATUS.DOWN,
        color: '#4d4dff',
        label: '순위 하락',
    },
    8: {
        icon: <_rankMaintainIcon />,
        status: STATUS.MAINTAIN,
        color: '#d1d1d1',
        label: '순위 유지',
    },
    9: {
        icon: <_rankDownIcon />,
        status: STATUS.DOWN,
        color: '#4d4dff',
        label: '순위 하락',
    },
} as const;

const SearchContent = ({ closeFunc }: Props) => {
    const router = useRouter();
    const [words, setWords] = useState<string[]>([]);
    const [userIdx, setUserIdx] = useState<string | null>(null);

    const { mutate } = useMutation({
        mutationFn: api,
    });
    useEffect(() => {
        const token = getCookie('accessToken');
        if (token) {
            const decoded: any = jwtDecode(token);
            console.log('decoded: ->', decoded);
            setUserIdx(decoded.userIdx);
        }
    }, []);

    // 인기검색어
    const { data: hotKeywords } = useQuery<HotKeywords>({
        queryKey: ['hotKeywords'],
        queryFn: () =>
            api({
                method: 'GET',
                url: '/search/hot-keywords',
            }),
    });

    // 추천검색어
    const { data: recommendKeywords, refetch: recommendKeywordsRefetch } = useQuery<RecommendKeywords>({
        queryKey: ['recommendKeywords'],
        queryFn: () =>
            api({
                method: 'GET',
                url: `/search/recommend-keywords`,
                params: {
                    customerIdx: userIdx,
                },
            }),
        enabled: !!userIdx,
    });
    // 최근 검색어
    const { data: recentKeywords, refetch: recentKeywordsRefetch } = useQuery<RecentKeywords>({
        queryKey: ['recentKeywords'],
        queryFn: () =>
            api({
                method: 'GET',
                url: '/search/recent-keywords',
                params: {
                    customerIdx: userIdx,
                },
            }),
        enabled: !!userIdx,
    });

    // 최근 검색어 삭제
    const deleteRecentKeyword = (keywordIdx: string) => {
        mutate(
            {
                method: 'DELETE',
                url: '/search/delete-recent-keyword',
                params: {
                    customerIdx: userIdx,
                    keywordIdx: keywordIdx,
                },
            },
            {
                onSuccess: () => {
                  // 로컬 스토리지에서 삭제된 키워드를 업데이트
                  const text = localStorage.getItem('ecf_search_word') || '[]'
                  let existingTerms: string[] = JSON.parse(text);

                  // 해당 키워드를 삭제
                  existingTerms = existingTerms.filter((keyword) => keyword !== keywordIdx);

                  // 로컬 스토리지에 업데이트된 목록 저장
                  localStorage.setItem('ecf_search_word', JSON.stringify(existingTerms));

                  // 화면에 최근 검색어 목록 갱신
                  setWords(existingTerms.slice(0,9)); 
                  recentKeywordsRefetch();
                  recommendKeywordsRefetch();
                },
            }
        );
    };

    // 최근 검색어 모두 삭제
    const deleteAllRecentKeyword = () => {
        mutate(
            {
                method: 'DELETE',
                url: '/search/delete-all-recent-keyword',
                params: {
                    customerIdx: userIdx,
                },
            },
            {
                onSuccess: () => {
                    recentKeywordsRefetch();
                    recommendKeywordsRefetch();
                },
            }
        );
    };

    const deleWord = () => {
        localStorage.removeItem('ecf_search_word');
        setWords([]);
    };

    useEffect(() => {
        //localStorage.setItem('ecf_search_word', JSON.stringify(dummy));
        const text = localStorage.getItem('ecf_search_word');
        console.log(text);
        if (text) {
            const array = JSON.parse(text) as string[];
            const maxArray = array.slice(0, 9);
            setWords(maxArray);
        }
    }, []);

    return (
        <_inner>
            <_div>
                <p>인기 검색어</p>
                <ul>
                    {hotKeywords?.data?.keywords?.map((ele, idx) => {
                        // 디폴트는 MAINTAIN
                        const rankInfo = RANK_STATUS[idx as keyof typeof RANK_STATUS] || {
                            icon: <_rankMaintainIcon />,
                            status: STATUS.MAINTAIN,
                            color: '#d1d1d1',
                            label: '순위 유지',
                        };
                        return (
                            <_rankDiv key={idx}>
                                <_rankContainer
                                    onClick={() => {
                                        saveSearch(ele);
                                        location.href = `/search?keyword=${ele}`;
                                        closeFunc?.();
                                    }}>
                                    <_rankWrap>
                                        <span className="rank">{idx + 1}</span>
                                        <span className="title">{ele}</span>
                                        <_statusWrapper>{rankInfo.icon}</_statusWrapper>
                                    </_rankWrap>
                                    {/* <span className="rank">{ele.rank}</span>
                  <span className="title">{ele.title}</span> */}
                                </_rankContainer>
                                {/* <span className={ele.status || ''}>
                  {ele.status === 'up' && (
                    <Image src={increase} alt={ele.status || ''} />
                  )}
                  {ele.status === 'down' && (
                    <Image src={decrease} alt={ele.status || ''} />
                  )}
                  <span>{ele.rate ? Math.abs(ele.rate) : '-'}</span>
                </span> */}
                            </_rankDiv>
                        );
                    })}
                </ul>
            </_div>
            {userIdx ? (
                <_div>
                    <p>
                        최근 검색어
                        <_span onClick={deleteAllRecentKeyword}>모두 삭제</_span>
                    </p>
                    <div className="scrollWrap blind-scroll">
                        <_wrap2>
                            {recentKeywords?.data?.keywords.length ? (
                                recentKeywords?.data?.keywords.map((ele, idx) => {
                                    return (
                                        <_word key={idx}>
                                            <span
                                                onClick={() => {
                                                    saveSearch(ele.recentKeyword);
                                                    router.push(`/search?keyword=${ele.recentKeyword}`);
                                                    closeFunc?.();
                                                }}>
                                                {ele.recentKeyword}
                                            </span>
                                            <Image
                                                src={close}
                                                alt="delete word"
                                                onClick={() => {
                                                    deleteRecentKeyword(ele.idx);
                                                }}
                                            />
                                        </_word>
                                    );
                                })
                            ) : (
                                <_no>최근검색어가 없습니다</_no>
                            )}
                        </_wrap2>
                    </div>
                </_div>
            ) : (
                <_div>
                    <p>
                        최근 검색어
                        <_span onClick={deleWord}>모두 삭제</_span>
                    </p>
                    <div className="scrollWrap blind-scroll">
                        <_wrap2>
                            {words?.length ? (
                                words.map((ele, idx) => {
                                    return (
                                        <_word key={idx}>
                                            <span
                                                onClick={() => {
                                                    saveSearch(ele);
                                                    router.push(`/search?keyword=${ele}`);
                                                    closeFunc?.();
                                                }}>
                                                {ele}
                                            </span>
                                            <Image
                                                src={close}
                                                alt="delete word"
                                                onClick={() => {
                                                    // const newWord = deleteSearch(ele, setWords);
                                                    // setWords(newWord);
                                                     deleteSearch(ele,setWords);
                                                }}
                                            />
                                        </_word>
                                    );
                                })
                            ) : (
                                <_no>최근검색어가 없습니다</_no>
                            )}
                        </_wrap2>
                    </div>
                </_div>
            )}

            {recommendKeywords?.data.keywords && recommendKeywords?.data.keywords.length > 0 ? (
                <_div>
                    <p>추천 검색어</p>
                    <div className="scrollWrap blind-scroll">
                        <_wrap>
                            {recommendKeywords?.data.keywords.map((ele, idx) => {
                                return (
                                    <_word key={idx}>
                                        <span
                                            onClick={() => {
                                                saveSearch(ele);
                                                router.push(`/search?keyword=${ele}`);
                                                closeFunc?.();
                                            }}>
                                            {ele}
                                        </span>
                                    </_word>
                                );
                            })}
                        </_wrap>
                    </div>
                </_div>
            ) : (
                <_div>
                    <p>추천 검색어</p>
                    <_no style={{ fontSize: '16px' }}>추천 검색어가 없습니다</_no>
                </_div>
            )}
        </_inner>
    );
};

export default SearchContent;

const _statusWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const _rankContainer = styled.div`
    width: 100%;
    cursor: pointer;
`;

const _rankWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    width: 100%;

    .rank {
        font-size: 14px;
        font-weight: 400;
        color: #787878;
        min-width: 15px;
        font-family: ${theme.fontFamily.en};
        text-align: center;
    }

    .title {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin-left: 5px;
    }

    @media screen and (max-width: 1026px) {
        .rank {
            min-width: 12px;
        }
    }
`;

const _inner = styled.div`
    width: 670px;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #e0e0e0;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 1026px) {
        width: calc(100% - 16px);
        grid-template-columns: repeat(1, 1fr);
        border: none;
    }
`;

const _div = styled.div`
    > p {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        position: relative;
        margin-bottom: 24px;
    }
    padding-left: 32px;
    padding-right: 32px;
    &:nth-of-type(1) {
        border-right: 1px solid #e0e0e0;
        padding-top: 40px;
        padding-bottom: 24px;
        @media screen and (max-width: 1026px) {
            padding: 24px 16px;
            border-right: none;
            border-top: 1px solid #e0e0e0;
            order: 3;
            > ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 20px;
            }
        }
    }
    &:nth-of-type(2) {
        padding-top: 40px;
        padding-bottom: 24px;
        @media screen and (max-width: 1026px) {
            padding: 24px 16px;
            order: 1;
            width: 100%;
            overflow-x: hidden;
            .scrollWrap {
                width: 100%;
                position: relative;
                overflow-x: auto;
            }
        }
    }
    &:nth-of-type(3) {
        grid-column: 1 / -1;
        border-top: 1px solid #e0e0e0;
        padding-top: 24px;
        padding-bottom: 16px;
        > p {
            margin-bottom: 16px;
        }
        @media screen and (max-width: 1026px) {
            padding: 24px 16px;
            order: 2;
            width: 100%;
            overflow-x: hidden;
            .scrollWrap {
                width: 100%;
                position: relative;
                overflow-x: auto;
            }
        }
    }
`;

const _span = styled.span`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #787878;
    cursor: pointer;
`;

const _word = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    border: 1px solid #c8c8c8;
    margin-right: 8px;
    margin-bottom: 8px;
    line-height: 20px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    > span {
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > img {
        margin-left: 10px;
        cursor: pointer;
        display: inline-block;
    }

`;

const _wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: 1026px) {
        width: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; /* 자식 요소를 한 줄로 정렬 */
    }
`;

const _wrap2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    > span {
        width: max-content !important;
    }
    @media screen and (max-width: 1026px) {
        width: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; /* 자식 요소를 한 줄로 정렬 */
    }
`;

const _rankDiv = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 16px;
    color: #a0a0a0;
    position: relative;

    @media screen and (max-width: 1026px) {
        width: 100%;

        > span {
            width: 16px;
            text-align: center;
        }
    }
`;

// const _rankDiv = styled.li`
//     display: flex;
//     align-items: center;
//     margin-bottom: 16px;
//     width: 100%;

//     &:last-child {
//         margin-bottom: 0;
//     }

//     @media screen and (max-width: 1026px) {
//         margin-bottom: 12px;
//     }
// `;

const _no = styled.p`
    color: #c8c8c8;
`;
